
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function DataTableBodyCell({ noBorder, align, children, cellKey }) {
    const { light } = colors;
    const { size } = typography;
    const { borderWidth } = borders;

    return (
        <SoftBox
            component="td"
            textAlign={align}
            fontSize={size.md}
            borderBottom={noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`}
            borderRight={`${borderWidth[1]} solid ${light.main}`}
            py={1.5}
            px={3}
        >
            <SoftBox
                display="inline-block"
                width="max-content"
                color="black"
                sx={cellKey === 0 ? { verticalAlign: "middle", maxWidth: '400px' }: { verticalAlign: "middle" }}
            >
                {children}
            </SoftBox>
        </SoftBox>
    );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
    noBorder: false,
    align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
    children: PropTypes.node.isRequired,
    noBorder: PropTypes.bool,
    cellKey: PropTypes.number,
    align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
