import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import TableCell from "@mui/material/TableCell";

function DataTableHeadCell({ width, children, sorted, align, ...rest }) {
    const { light } = colors;
    const { borderWidth } = borders;

    return (
        <TableCell
            borderRight={`${borderWidth[1]} solid ${light.main}`}
            style={{border: `${borderWidth[1]} solid ${light.main}`}}
        >
            <SoftBox
                {...rest}
                position="relative"
                textAlign={align}
                color="black"
                opacity={0.7}
                borderRight={`${borderWidth[1]} solid ${light.main}`}
                sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xxs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                })}
            >
                {children}
            </SoftBox>
        </TableCell>
    );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
    width: "auto",
    sorted: "none",
    align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
    sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
    align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
