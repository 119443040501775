import Shop from "app/components/Icons/Shop";
import Home from "./app/pages/Home";
import KeywordTool from "./app/pages/KeywordTool";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Shop size="12px" />,
    collapse: [
        {
            name: "Home",
            key: "home",
            route: "/",
            component: <Home />,
        },
        {
            name: "KeywordTool",
            key: "keyword-tool",
            route: "/amazon-keyword-research-tool",
            component: <KeywordTool />,
        },
      ]
  },
];

export default routes;
