import { useEffect } from 'react';

const GA_MEASUREMENT_ID = 'G-475WSNX9G3'; // Replace with your actual GA Measurement ID

function useGoogleAnalytics() {
    useEffect(() => {
        // Initialize the Google Analytics tag
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', GA_MEASUREMENT_ID);

        // Load the gtag script
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
        document.head.appendChild(script);
    }, []);
}

export default useGoogleAnalytics;
