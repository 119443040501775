/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import SoftProgress from "../../../components/SoftProgress";

function TableExtraRow({ color,  noDivider, row }) {
    const { borderWidth } = borders;

    return (
        <SoftBox
            component="li"
            width="100%"
            pr={2}
            mb={2}
            borderLeft={`${borderWidth[3]} solid ${colors[color].main}`}
            sx={{ listStyle: "none" }}
        >
            <SoftBox width="100%" pl={1} ml={2}>
                <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start"}}
                    flexDirection={{ xs: "column", sm: "column" }}
                    mt={2}
                    ml={3}
                    pl={0.5}
                >
                    <SoftBox lineHeight={1} mb={{ xs: 1, sm: 0 }}>
                        <div>
                            <SoftTypography variant="caption" fontWeight="medium" color="secondary">
                                Category:
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>
                                {row.original.category}
                            </SoftTypography>
                        </div>
                    </SoftBox>

                    <SoftBox lineHeight={1} mb={{ xs: 1, sm: 0 }}>
                        <div>
                            <SoftTypography variant="caption" fontWeight="medium" color="secondary">
                                Size Tier:
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>
                                {row.original.sizeTier}
                            </SoftTypography>
                        </div>
                    </SoftBox>

                    {/*<SoftBox component="li" w="100%" py={1} mb={0.5}>*/}
                    {/*    <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>*/}
                    {/*        <SoftTypography variant="caption" fontWeight="medium" color="secondary">*/}
                    {/*            Competition:*/}
                    {/*        </SoftTypography>*/}
                    {/*        <SoftTypography variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>*/}
                    {/*            Very Hard*/}
                    {/*        </SoftTypography>*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftProgress variant="gradient" color="error" value={90} />*/}
                    {/*</SoftBox>*/}

                </SoftBox>
            </SoftBox>
            {noDivider ? null : <Divider sx={{ marginBottom: 0 }} />}
        </SoftBox>
    );
}

// Setting default values for the props of Todo
TableExtraRow.defaultProps = {
    color: "info",
    noDivider: false,
    defaultChecked: false,
};

// Typechecking props for the Todo
TableExtraRow.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    row: PropTypes.object.isRequired,
    defaultChecked: PropTypes.bool,
    noDivider: PropTypes.bool,
};

export default TableExtraRow;
