import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";

const categories = ['', 'Amazon Devices & Accessories', 'Amazon Launchpad', 'Amazon Renewed', 'Appliances', 'Arts, Crafts & Sewing', 'Audible Books & Originals', 'Automotive', 'Baby', 'Beauty & Personal Care', 'Books', 'CDs & Vinyl', 'Camera & Photo Products', 'Cell Phones & Accessories', 'Clothing, Shoes & Jewelry', 'Collectible Currencies', 'Computers & Accessories', 'Electronics', 'Gift Cards', 'Grocery & Gourmet Food', 'Handmade Products', 'Health & Household', 'Home & Kitchen', 'Industrial & Scientific', 'Kindle Store', 'Kitchen & Dining', 'Movies & TV', 'Musical Instruments', 'N\\A', 'Office Products', 'Our Brands', 'Patio, Lawn & Garden', 'Pet Supplies', 'Software', 'Sports & Outdoors', 'Sports Collectibles', 'Tools & Home Improvement', 'Toys & Games', 'Video Games']
const size_tiers = ['', 'Large Oversize', 'Large Standard-Size', 'Medium Oversize', 'N/A', 'Small Oversize', 'Small Standard-Size', 'Special Oversize']


function KeywordFormExpert({formHandler, submitHandler, formData}) {

    const [categoryValue, setCategoryValue] = useState(null);
    const [sizeTierValue, setSizeTierValue] = useState(null);

    const handleCategoryChange = (event) => {
        event = {target: {name: "category", value: event.value}};
        setCategoryValue(event.value)
        formHandler(event);
    }

    const handleSizeTierChange = (event) => {
        event = {target: {name: "sizeTier", value: event.value}};
        setSizeTierValue(event.value)
        formHandler(event);
    }

    const atLeastOneFilterSelected = () => {
        return formData.keyword || formData.category || formData.searchVolumeFrom || formData.searchVolumeTo || formData.priceFrom || formData.priceTo || formData.sizeTier || formData.monthlyRevenueFrom || formData.monthlyRevenueTo || formData.monthlySalesFrom || formData.monthlySalesTo;
    }

    return (
        <Card id="basic-info"  sx={{ overflow: "visible", marginTop: '-50px'}}>
            <SoftBox component="form" pb={3} px={3}>
                <SoftBox p={3}>
                    <SoftTypography variant="h5">Amazon Keyword Research Tool</SoftTypography>
                </SoftBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            name="keyword"
                            label="Keyword"
                            placeholder="Enter keyword..."
                            onChange={formHandler}
                            value={formData.keyword}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Category
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        name="category"
                                        value={categoryValue}
                                        onChange={(e) => handleCategoryChange(e)}
                                        placeholder="Any"
                                        options={
                                            categories.map((category) => {
                                                return { value: category, label: category ? category : 'Any' }
                                            })
                                        }
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="search volume min"
                                    inputProps={{ type: "number" }}

                                    name="searchVolumeFrom"
                                    onChange={formHandler}
                                    value={formData.searchVolumeFrom}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="search volume max"
                                    inputProps={{ type: "number" }}

                                    name="searchVolumeTo"
                                    onChange={formHandler}
                                    value={formData.searchVolumeTo}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="price min"
                                    inputProps={{ type: "number" }}

                                    name="priceFrom"
                                    onChange={formHandler}
                                    value={formData.priceFrom}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="price max"
                                    inputProps={{ type: "number" }}

                                    name="priceTo"
                                    onChange={formHandler}
                                    value={formData.priceTo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={4}>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Size Tier
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        value={sizeTierValue}
                                        onChange={(e) => handleSizeTierChange(e)}
                                        placeholder="Any"
                                        options={
                                            size_tiers.map((size_tier) => {
                                                return { value: size_tier, label: size_tier ? size_tier : 'Any' }
                                            })
                                        }
                                    />
                                    {/*<SoftSelect placeholder="Any" options={categories} />*/}
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="monthly revenue min"
                                    inputProps={{ type: "number" }}

                                    name="monthlyRevenueFrom"
                                    onChange={formHandler}
                                    value={formData.monthlyRevenueFrom}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="monthly revenue max"
                                    inputProps={{ type: "number" }}

                                    name="monthlyRevenueTo"
                                    onChange={formHandler}
                                    value={formData.monthlyRevenueTo}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="monthly sales min"
                                    inputProps={{ type: "number" }}

                                    name="monthlySalesFrom"
                                    onChange={formHandler}
                                    value={formData.monthlySalesFrom}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormField
                                    label="monthly sales max"
                                    inputProps={{ type: "number" }}

                                    name="monthlySalesTo"
                                    onChange={formHandler}
                                    value={formData.monthlySalesTo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <SoftBox mt={3}>
                    <SoftButton
                        variant="gradient"
                        color={'info'}
                        fullWidth
                        // size={'medium'}
                        onClick={submitHandler}
                        disabled={!atLeastOneFilterSelected()}
                    >
                        {'Submit'}&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Typechecking props for the Header
KeywordFormExpert.propTypes = {
    formHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default KeywordFormExpert;
