import React from "react";
import { Container, Grid, Card, Button, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "app/components/LayoutContainers/PageLayout";
import DefaultNavbar from "app/components/Navbars/DefaultNavbar";
import SoftTypography from "components/SoftTypography";
import waves from "assets/images/shapes/waves-white.svg";
import pageRoutes from "page.routes";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {CancelOutlined} from "@mui/icons-material";
import SoftButton from "../../../components/SoftButton";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon"; // For bullet points
import feature from "assets/gifs/feature3.gif";
import data1 from "assets/images/asinly/cheap.png";
import data2 from "assets/images/asinly/data2.png";
import data3 from "assets/images/asinly/data3.png";
import Divider from "@mui/material/Divider";
import Footer from "../../components/Footer";

function Home() {
    return (
        <PageLayout>
            <DefaultNavbar
                routes={pageRoutes}
                action={{
                    type: "external",
                    route: "https://asinly.com",
                    label: "buy now",
                    color: "dark",
                }}
                transparent
                light
            />
            <SoftBox
                position="relative"
                minHeight="60vh" // Increased height for better visual spacing
                borderRadius="xl"
                overflow="hidden"
                bgColor="info"
                variant="gradient"
                m={2}
                pt={12}
                pb={24} // Increased padding for more space
            >
                <SoftBox
                    component="img"
                    src={waves}
                    alt="waves"
                    width="105rem"
                    position="absolute"
                    top={0}
                />
                <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                    <Grid item xs={10} lg={5}>
                        <SoftBox mb={1}>
                            <SoftTypography variant="h1" color="white" fontWeight="bold">
                                Unlock the Power of Amazon Keyword Research
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography variant="body2" color="white" fontWeight="regular">
                                Discover high-demand search terms in seconds. Fast. Free. Effective.
                            </SoftTypography>
                        </SoftBox>
                            <SoftButton
                                component={Link}
                                to={"/amazon-keyword-research-tool"}
                                variant="gradient"
                                color={"info"}
                                fullWidth
                            >
                                Start Your Free Search Now&nbsp;
                                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                            </SoftButton>

                    </Grid>
                </Grid>
            </SoftBox>

            <Container>
                <SoftBox mb={6}> {/* Increased margin for more space */}
                    <Card sx={{ overflow: "visible", marginTop: "-100px" }} elevation={10}> {/* Increased elevation for depth */}
                        <Grid container spacing={6} p={4}> {/* Increased padding and spacing */}
                            {/* Why Choose Us Section */}
                            <Grid item xs={12} md={12}>
                                <SoftBox  textAlign="center">
                                    <SoftTypography variant="h4" fontWeight="bold" color="info" style={{'margin-bottom': '20px'}}>
                                        {/*🎯 For Sellers Who Demand Success*/}
                                        🎯 Free Of Use For Early Users!
                                    </SoftTypography>
                                    <Grid container spacing={6} p={4}>
                                    <Grid item xs={6} md={6}>
                                        <img src={data1} alt="data1" width={"70%"} style={{'border-radius': '20px'}} />
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <List dense>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CancelOutlined color="error" />
                                                </ListItemIcon>
                                                <ListItemText primary="Stop overpaying for expensive tools" />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <CheckCircleOutlineIcon color="success" />
                                                </ListItemIcon>
                                                <ListItemText primary="Uncover high-demand search terms" />
                                            </ListItem>
                                        </List>

                                        <SoftTypography variant="body1" fontWeight="regular" color="dark" style={{
                                            'text-align': 'start',
                                            'font-size': '18px',
                                            'margin-top': '20px',
                                        }}>
                                            Stop overpaying for expensive tools. Our free tool empowers you to uncover high-demand search terms, optimizing your listings for success without the hefty price tag.
                                        </SoftTypography>
                                    </Grid>
                                    </Grid>

                                    <Divider/>
                                    <SoftButton
                                        component={Link}
                                        to={"/amazon-keyword-research-tool"}
                                        variant="gradient"
                                        color={"info"}
                                    >
                                        Start Your Free Search Now&nbsp;
                                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                    </SoftButton>
                                    <Divider/>

                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <SoftBox textAlign="center">
                                    <SoftTypography variant="h4" fontWeight="bold" color="info" style={{'margin-bottom': '20px'}}>
                                        📈 Comprehensive Keyword Access
                                    </SoftTypography>
                                    <Grid container spacing={6} p={4}>
                                        <Grid item xs={6} md={6}>
                                            <List dense>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    {/*<ListItemText primary="Dive into millions of Amazon search terms. With our tool, valuable data is at your fingertips, free and user-friendly." />*/}
                                                    <ListItemText primary="Millions of Amazon search terms" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    {/*<ListItemText primary="Dive into millions of Amazon search terms. With our tool, valuable data is at your fingertips, free and user-friendly." />*/}
                                                    <ListItemText primary="Valuable data is at your fingertips" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    {/*<ListItemText primary="Dive into millions of Amazon search terms. With our tool, valuable data is at your fingertips, free and user-friendly." />*/}
                                                    <ListItemText primary="Beginner user-friendly" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Free" />
                                                </ListItem>
                                            </List>

                                            <SoftTypography variant="body1" fontWeight="regular" color="dark" style={{
                                                'text-align': 'start',
                                                'font-size': '18px',
                                                'margin-top': '20px',
                                            }}>
                                                Dive into millions of Amazon search terms. With our tool, valuable data is at your fingertips, free and user-friendly.
                                            </SoftTypography>
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                            <img src={data2} alt="data2" width={"100%"} style={{'border-radius': '20px'}} />
                                        </Grid>

                                    </Grid>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <SoftBox textAlign="center">
                                    <SoftTypography variant="h4" fontWeight="bold" color="info" style={{'margin-bottom': '20px'}}>
                                        🚀 Real and Up-to-Date
                                    </SoftTypography>

                                    <Grid container spacing={6} p={4}>
                                        <Grid item xs={6} md={6}>
                                            <img src={data3} alt="data3" width={"100%"} style={{'border-radius': '20px'}} />
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                            <List dense>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Real exposed Amazon search volume data" />
                                                </ListItem>

                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Refined & up to date" />
                                                </ListItem>
                                            </List>

                                            <SoftTypography variant="body1" fontWeight="regular" color="dark" style={{
                                                'text-align': 'start',
                                                'font-size': '18px',
                                                'margin-top': '20px',
                                            }}>
                                                <p>Amazon exposed search volume data in past years.</p>
                                                <p>We have collected and indexed this data to provide it to you for free.</p>
                                                <p>We refined this data with our internal processes to keep it up to date.</p>
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Grid>
                            {/* Trust and Credibility Section */}
                            <Grid item xs={12}>
                                <SoftBox p={3} textAlign="center">
                                    <SoftTypography variant="h4" fontWeight="bold" color="dark">
                                        Why Trust Us?
                                    </SoftTypography>
                                    <SoftBox my={2}>
                                        <SoftTypography variant="body1" fontWeight="regular" color="dark">
                                            As pioneers in the Amazon E-Commerce landscape, we have developed tools that have scaled the largest Amazon sellers & aggregators worldwide. Our mission now is to equip the community with these powerful resources. Committed to excellence, we offer unparalleled tools at the best value.
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                        </Grid>
                        <SoftBox display="flex" justifyContent="center" mt={4} pb={4}>
                            <img src={feature} alt="feature" width={"60%"} style={{'border-radius': '20px', 'border': 'white'}} />
                        </SoftBox>

                        <Grid container spacing={6} p={4}>
                            <Grid item xs={12} md={12}>
                                <SoftBox  textAlign="center">
                                    <Divider/>
                                    <SoftButton
                                        component={Link}
                                        to={"/amazon-keyword-research-tool"}
                                        variant="gradient"
                                        color={"info"}
                                    >
                                        Start Now&nbsp;
                                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                    </SoftButton>
                                    <Divider/>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </Card>
                </SoftBox>
            </Container>

            <SoftBox mt={8}>
                <Footer />
            </SoftBox>
        </PageLayout>
    );
}

export default Home;

