import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import { saveAs } from 'file-saver';

function MetaTableView({metaResults, pageData}) {

    // Helper function to convert JSON to CSV format
    const convertToCSV = ({ pageData }) => {

        let csvString = '';

        let headers = [];

        pageData.forEach((values) => {
            for (const [key, value] of Object.entries(values)) {
                if (!headers.includes(key)) {
                    headers.push(key);
                }
            }
        });

        csvString += headers.join('\t') + '\n';

        pageData.forEach((values) => {
            let row_values = [];
            for (const [key, value] of Object.entries(values)) {
                row_values.push(value);
            }

            csvString += row_values.join('\t') + '\n';
        });

        return csvString;
    };

    // Export current page data to CSV
    const exportPage = () => {
        const csvData = convertToCSV({ pageData: pageData });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        const filename_date = new Date().toISOString().slice(0, 10);
        saveAs(blob, 'asinly_keywords_export_' + filename_date + '.csv');
    };

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox pt={2} px={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                    Dominant Categories
                </SoftTypography>
            </SoftBox>
            <SoftBox pt={2} px={2}>
                <SoftBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    {metaResults.categoryData.slice(0, 2).map((category, index) => {
                        return (
                            <SoftBox key={index} component="li" w="100%" py={1} mb={0.5}>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                        textTransform="capitalize"
                                    >
                                        {category.category}
                                    </SoftTypography>
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                        textTransform="capitalize"
                                    >
                                        {category.percentage}%
                                    </SoftTypography>
                                </SoftBox>
                                <SoftProgress variant="gradient" value={+category.percentage} />
                            </SoftBox>
                        )
                    })}

                    {/*<SoftBox component="li" w="100%" py={1} mb={0.5}>*/}
                    {/*    <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>*/}
                    {/*        <SoftTypography*/}
                    {/*            variant="button"*/}
                    {/*            fontWeight="medium"*/}
                    {/*            color="text"*/}
                    {/*            textTransform="capitalize"*/}
                    {/*        >*/}
                    {/*            neutral reviews*/}
                    {/*        </SoftTypography>*/}
                    {/*        <SoftTypography*/}
                    {/*            variant="button"*/}
                    {/*            fontWeight="medium"*/}
                    {/*            color="text"*/}
                    {/*            textTransform="capitalize"*/}
                    {/*        >*/}
                    {/*            17%*/}
                    {/*        </SoftTypography>*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftProgress variant="gradient" color="dark" value={17} />*/}
                    {/*</SoftBox>*/}
                    {/*<SoftBox component="li" w="100%" py={1} mb={0.5}>*/}
                    {/*    <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>*/}
                    {/*        <SoftTypography*/}
                    {/*            variant="button"*/}
                    {/*            fontWeight="medium"*/}
                    {/*            color="text"*/}
                    {/*            textTransform="capitalize"*/}
                    {/*        >*/}
                    {/*            negative reviews*/}
                    {/*        </SoftTypography>*/}
                    {/*        <SoftTypography*/}
                    {/*            variant="button"*/}
                    {/*            fontWeight="medium"*/}
                    {/*            color="text"*/}
                    {/*            textTransform="capitalize"*/}
                    {/*        >*/}
                    {/*            3%*/}
                    {/*        </SoftTypography>*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftProgress variant="gradient" color="error" value={3} />*/}
                    {/*</SoftBox>*/}
                </SoftBox>
            </SoftBox>
            <SoftBox pb={2} px={2} display="flex" flexDirection={{ xs: "column", sm: "row" }} mt="auto">
                {/*<SoftBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>*/}
                {/*    <SoftTypography variant="button" color="text" fontWeight="regular">*/}
                {/*        More than <strong>1,500,000</strong> developers used Creative Tim&apos;s products and*/}
                {/*        over <strong>700,000</strong>*/}
                {/*        projects were created.*/}
                {/*    </SoftTypography>*/}
                {/*</SoftBox>*/}

                <SoftBox width={{ xs: "100%", sm: "100%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
                    <SoftButton variant="gradient" color="info" onClick={exportPage}>
                        Export
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Typechecking props for the DataTableBodyCell
MetaTableView.propTypes = {
    metaResults: PropTypes.object.isRequired,
    pageData: PropTypes.array.isRequired,
};


export default MetaTableView;
