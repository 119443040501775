import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "app/components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "app/components/Tables/DataTable/DataTableBodyCell";
import TableExtraRow from "./TableExtraRow";
import TableHead from "@mui/material/TableHead";

function DataTable({
                       pageIndex,
                       onPageChange,
                       totalResults,
                       sortHandler,
                       formData,
                       entriesPerPage,
                       canSearch,
                       showTotalEntries,
                       table,
                       pagination,
                       isSorted,
                       noEndBorder,
                   }) {

    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 50;
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);

    const tableInstance = useTable(
        {columns, data},
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        setPageSize,
        state: {pageSize },
    } = tableInstance;

    const gotoPage = (page) => {
        onPageChange(page);
    }

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 50), [defaultValue]);

    // Setting the entries ending point
    let entriesEnd;

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    entriesEnd = Math.min(entriesStart + pageSize - 1, totalResults);

    const pageOptions = useMemo(() => {
        const options = [];

        for (let i = 0; i < Math.ceil(totalResults / pageSize); i += 1) {
            options.push(i);
        }

        return options;
    }, [totalResults, pageSize]);

    let canNextPage = pageIndex < pageOptions.length - 1;
    const canPreviousPage = pageIndex > 0;
    if (page.length <= 19) {
        canNextPage = false;
    }

    const renderPagination = pageOptions.map((option) => {
        if (option === 0 || ((page.length > 19) && (option >= pageIndex - 1 && option <= pageIndex + 1))) {
            return (
                <SoftPagination
                    item
                    key={option}
                    onClick={() => gotoPage(Number(option))}
                    active={pageIndex === option}
                >
                    {option + 1}
                </SoftPagination>
            )
        }
    });

    const handleNextPage = () => {
        pageIndex = pageIndex + 1;
        onPageChange(pageIndex);
    }

    const handlePreviousPage = () => {
        pageIndex = pageIndex - 1;
        onPageChange(pageIndex);
    }

    const handleSelectChange = (sorting) => {
        sortHandler(sorting);
    }

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    return (
        <TableContainer sx={{ boxShadow: "none", height: "calc(150vh)" }}
                        style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >
            <Table {...getTableProps()}>
                <SoftBox component="thead"
                         style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}
                >
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, key) => {
                                if (column.table) {
                                    return (
                                        <DataTableHeadCell
                                            key={key + '-header'}
                                            {...
                                                column.getHeaderProps()
                                            }
                                            width={column.width ? column.width : "auto"}
                                            align={column.align ? column.align : "left"}
                                        >
                                            {column.render("Header")}
                                        </DataTableHeadCell>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => {
                                    if (cell.column.table) {
                                        return (
                                            <DataTableBodyCell
                                                sx={{display: 'flex', flexDirection: 'row'}}
                                                key={key}
                                                cellKey={key}
                                                noBorder={noEndBorder && rows.length - 1 === key}
                                                align={cell.column.align ? cell.column.align : "left"}
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}

                                                {key === 0 && <TableExtraRow
                                                    defaultChecked
                                                    row={row}
                                                />}
                                            </DataTableBodyCell>
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display="flex"
                flexDirection={{xs: "column", sm: "row"}}
                justifyContent="space-between"
                alignItems={{xs: "flex-start", sm: "center"}}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{xs: 3, sm: 0}}>
                        <SoftTypography variant="button" color="secondary" fontWeight="regular">
                            {/*Showing {entriesStart} to {entriesEnd} of {totalResults} entries*/}
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => handlePreviousPage()}>
                                <Icon sx={{fontWeight: "bold"}}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination}

                        {canNextPage && (
                            <SoftPagination item onClick={() => handleNextPage()}>
                                <Icon sx={{fontWeight: "bold"}}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 50, entries: [5, 10, 15, 20, 25, 50] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    sortHandler: PropTypes.func.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    totalResults: PropTypes.number.isRequired,
    formData: PropTypes.object.isRequired,

    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
