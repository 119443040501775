import Card from "@mui/material/Card";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import PropTypes from "prop-types";
import DataTable from "./DataTable";
import MetaTableView from "./Table/MetaTableView";


function KeywordTable({tableData, metaResults, onPageChange, page, sortHandler, formData}) {
    const handlePaginationClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const handleSorting = (sort) => {
        sortHandler(sort);
    }

    return (
        <SoftBox mb={3} sx={{marginTop: "30px"}}>
            <Card>
                <SoftBox p={3} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                        Keyword Landscape
                    </SoftTypography>
                    <MetaTableView metaResults={metaResults} pageData={formData.pageData} />
                </SoftBox>
                <DataTable
                    table={tableData}
                    totalResults={1000}
                    onPageChange={handlePaginationClick}
                    pageIndex={page - 1}
                    sortHandler={handleSorting}
                    formData={formData}
                />
            </Card>
        </SoftBox>
    );
}

// Typechecking props for the Header
KeywordTable.propTypes = {
    tableData: PropTypes.object.isRequired,
    metaResults: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    sortHandler: PropTypes.func,
    formData: PropTypes.object.isRequired,
};

export default KeywordTable;