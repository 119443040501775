import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import SoftBox from "components/SoftBox";
import PageLayout from "app/components/LayoutContainers/PageLayout";
const apiUrl = process.env.REACT_APP_API_URL;

// Pricing page components
import Header from "app/pages/KeywordTool/header";
import Footer from "layouts/pages/pricing-page/components/Footer";
import KeywordFormExpert from "./keywordFormExpert";
import KeywordTable from "./KeywordTable";
import axios from "axios";
import SoftTypography from "../../../components/SoftTypography";
import {useLocation} from "react-router-dom";
import {PuffLoader} from "react-spinners";

function KeywordTool() {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [metaResults, setMetaResults] = useState();

    const location = useLocation();

    const [formData, setFormData] = useState({
        keyword: "",

        category: "",
        searchVolumeFrom: "",
        searchVolumeTo: "",
        priceFrom: "",
        priceTo: "",

        sizeTier: "",
        monthlySalesFrom: "",
        monthlySalesTo: "",
        monthlyRevenueFrom: "",
        monthlyRevenueTo: "",

        page: 1,
        pageData: [],
    });


    const apiCall = (updatedFormData) => {
        setLoading(true);

        console.log(apiUrl);

        axios.post(apiUrl + '/api/keywords/search', updatedFormData).then((response) => {
            const rows = [];
            const categories = [];

            response.data.data.forEach((row) => {
                const dataRow = {
                    phrase: row.keyword,
                    category: row.category,
                    sizeTier: row.sizeTier,
                    searchVolume: row.searchVolume,
                    monthlyRevenue: row.monthlyRevenue,
                    monthlySales: row.monthlySales,
                    price: row.price,
                    reviewCount: row.reviewCount,
                    reviewRating: row.reviewRating,
                };

                categories.push(row.category);
                rows.push(dataRow)
            });

            setFormData({...updatedFormData, pageData: rows});

            const columns = [
                {Header: 'Phrase', accessor: 'phrase', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'18px'} fontWeight={'bold'} width={'350px'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Search Volume', accessor: 'searchVolume', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'} color={'info'}>
                                {row.value?.toLocaleString()}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Revenue', accessor: 'monthlyRevenue', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'}>
                                $ <b>{row.value?.toLocaleString()}</b>
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Sales', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value && row.value.toLocaleString()}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Price', accessor: 'price', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftBox my={1}>
                                <SoftTypography fontSize={'16px'}>
                                    $ <b>{row.value?.toLocaleString()}</b>
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    ) }
                },
                {Header: 'Review Count', accessor: 'reviewCount', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftBox my={1}>
                                <SoftTypography fontSize={'16px'}>
                                    <b>{row.value?.toLocaleString()}</b>
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    ) }
                },
                {Header: 'Review Rating', accessor: 'reviewRating', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftBox my={1}>
                                <SoftTypography fontSize={'16px'}>
                                    <b>{row.value?.toLocaleString()}</b>
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    ) }
                },
            ];

            const categoryMetaData = []
            const categoryData = {};
            categories.forEach(category => {
                if (category in categoryData) {
                    categoryData[category]++;
                } else {
                    categoryData[category] = 1;
                }
            });
            const totalCategories = categories.length;
            Object.keys(categoryData).forEach(category => {
                const categoryPercentage = ((categoryData[category] / totalCategories) * 100).toFixed(2);
                categoryData[category] = { count: categoryData[category], percentage: categoryPercentage, category: category };
                categoryMetaData.push(categoryData[category]);
            });

            const sortedCategoryData = categoryMetaData.sort((a, b) => b.percentage - a.percentage)

            setMetaResults({categoryData: sortedCategoryData});

            const result = {
                "rows": rows,
                "columns": columns
            };
            setResults(result);

        }).catch((error) => {
            alert(error)
            setLoading(false)
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
        const page = 1;
        const updatedFormData = { ...formData, page};
        setFormData({...formData, page});

        apiCall(updatedFormData);
    };

    useEffect(() => {
        // scroll to bottom on results change
        if (results) {
            window.scrollTo(0,800);
        }
    }, [results]);


    const handlePageChange = (newPage) => {
        newPage = newPage + 1;
        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    return (
        <PageLayout>
            <Header/>
            <Container>
                <KeywordFormExpert formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />

                {loading && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", height: '400px' }}>
                        <PuffLoader
                            color={'#17c1e8'}
                            loading={loading}
                            size={80}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />

                    </div>
                )}

                {!loading && results && <KeywordTable
                    tableData={results}
                    metaResults={metaResults}

                    key={formData.page}
                    page={formData.page}
                    onPageChange={handlePageChange}
                    formData={formData}
                />}
            </Container>

            {/*<SoftBox mt={8}>*/}
            {/*    <Footer />*/}
            {/*</SoftBox>*/}
        </PageLayout>
    );
}

export default KeywordTool;
